import React, { useState, useEffect, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { forEach, filter, uniq, concat, chunk } from 'lodash'

import { parseACF, renderLink } from '../utils'
import { Layout, Tabs, ImageOrVideo } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, textDot } from '../styles/global'

import { headerHeight } from '../components/Header/Header'

import { PageTitleContext } from '../context/PageTitleContext'
import { useMount } from 'react-use'
import queryString from 'query-string'

import { isClient } from '../styles/utils'

const PAGE_SIZE = 12

const News = props => {
    const { previewData, pageContext } = props
    const graphQuery = useStaticQuery(query)
    const data = previewData || parseACF(graphQuery, 'allWordpressInfopages')
    const { pageTitle, setPageTitle } = useContext(PageTitleContext)
    const [filters, setFilters] = useState([])
    const [activeFilter, setActiveFilter] = useState('all')
    const news = data?.news?.news
    const [activeItems, setActiveItems] = useState(false)

    // pagination
    const [paginate, setPaginate] = useState(false)
    const [currentPageData, setCurrentPageData] = useState(false)
    const [activePage, setActivePage] = useState(1)

    const parseURL = () => {
        if (!isClient()) return

        let params = queryString.parse(window.location.search)

        if (params?.filter) {
            setActiveFilter(params?.filter)
        }
    }

    const getNewsCats = (news) => {
        if (!news) return
    
        let cats = []
    
        forEach(news, (article, i) => {
            if (!article.cats) return
    
            forEach(article.cats, (cat) => {
                cats.push(cat?.slug)
            })
        })
    
        return uniq(cats)
    }

    useMount(() => {
        setPageTitle(graphQuery?.allWordpressInfopages?.nodes?.[0]?.title)

        const cats = data?.news?.cats
        let catSlugs = ['all']
    
        forEach(cats, (cat, i) => {
            if (!cat.slug) return
            catSlugs.push(cat.slug)
        })

        if (!catSlugs) return

        setFilters(catSlugs)
        parseURL()
    })

    const filterGrid = thisFilter => {
        let results = news

        if (thisFilter && thisFilter !== 'all') {
            results = filter(results, item => {
                const cats = getNewsCats([item])
    
                let hasCat = false
    
                if (cats.length) {
                    forEach(cats, cat => {
                        if (thisFilter === cat)
                            hasCat = true
                    })
                }
    
                return hasCat
            })
        }

        return results
    }

    const toggleFilter = (filter) => {
        setActiveFilter(filter)
    }

    useEffect(() => {
        const items = filterGrid(activeFilter)
        setActiveItems(items)
    }, [activeFilter])

    useEffect(() => {
        if (activeItems) {
            if (activeItems?.length > PAGE_SIZE) {
                setPaginate(true)
            } else {
                setPaginate(false)
                setCurrentPageData(false)
                setActivePage(1)
            }
        }
    }, [activeItems])

    useEffect(() => {
        if (paginate) {
            const chunks = chunk(activeItems, PAGE_SIZE)
            if (chunks) {
                setCurrentPageData(chunks)
            }
        }
    }, [paginate])

    const renderFilters = () => {
        if (!filters) return

        const items = filters.map((item, i) => {
            const active = activeFilter === item
            let name = item.replace(/-/g, " ");
            if (name.includes('tv')) {
                name = name.replace(/tv/g, "TV");
            }

            return (
                <Item
                    key={i}
                    className={active && 'active'}
                    onClick={() => {
                        toggleFilter(item)
                    }}
                >
                    {name}
                </Item>
            )
        })

        return (
            <Filters>
                {items}
            </Filters>
        )
    }

    const renderGrid = (item) => {
        if (!activeItems) return

        if (paginate && !currentPageData) return

        let items

        if (paginate) {
            items = currentPageData[activePage-1]
        } else {
            items = activeItems
        }

        const gridItems = items.map((item, i) => {
            // console.log(item)
            return (
                <Item
                    as={Link}
                    key={i}
                    to={`/news/${item.slug}/`}
                >
                    <ImageWrapper>
                        {item?.thumbnail_media && (
                            <ImageOrVideo
                                media_type={item?.thumbnail_media?.media_type}
                                image={item?.thumbnail_media?.image}
                                video={item?.thumbnail_media?.video}
                                video_embed={item?.thumbnail_media?.video_embed}
                            />
                        )}
                    </ImageWrapper>
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: item.thumbnail_text || item.title,
                        }}
                    />
                </Item>
            )
        })

        let pagination_items

        if (paginate && currentPageData) {
            pagination_items = currentPageData?.map((item, i) => {
                return (
                    <Button
                        onClick={() => setActivePage(i+1)}
                        className={activePage === i+1 && 'active'}
                    >
                        {i+1}
                    </Button>
                )
            })
        }

        return (
            <ItemsOuter>
                {items.length > 0 && (
                    <Items>
                        {gridItems}
                    </Items>
                )}
                {items.length === 0 && (
                    <Description>
                        No Results Found
                    </Description>
                )}
                {paginate && pagination_items && (
                    <Pagination>
                        {pagination_items}
                    </Pagination>
                )}
            </ItemsOuter>
        )
    }

    const renderNews = () => {
        return (
            <Tabs
                activeItem={activeFilter}
                items={filters}
                renderItem={renderGrid}
            />
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            title={'News'}
        >
            <Wrapper>
                <Container>
                    {/* <PageHeader
                        heading={data?.intro_heading || 'News'}
                        text={data.intro_text && data.intro_text}
                    /> */}
                    {renderFilters()}
                    {renderNews()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Item = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: ${headerHeight};
`

const Container = styled.div`
    ${container};
    ${padding};
`

// Filters

const Filters = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-top: 2rem;
    padding: 1.5rem 0;
    ${tw`border-t border-darkGrey`};

    ${Item} {
        ${textDot}
        text-transform: capitalize;
        margin-right: 1rem;
        cursor: pointer;

        &.active {
            ${textDot('#000000')}
        }
    }
`

// Grid

const ItemsOuter = styled.div`
    width: 100%;
    overflow: hidden;

    // No results message
    > ${Description} {
        padding-bottom: 3rem;
    }
`

const ImageWrapper = styled.div``
const Gradient = styled.div``

const Items = styled.div`
    ${tw`flex flex-wrap`};
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    ${media.phone`
        ${tw`flex-col mx-0`};
    `}

    ${Item} {
        ${tw`w-1/4 border-darkGrey border-t border-b border-r `};
        padding: 1.5rem;

        &:nth-child(4n) {
           border-right: none;
        }

        &:nth-child(n+5) {
            border-top: none;
        }

        ${media.phone`
            ${tw`w-full px-0`};
            border: none;
        `}
    }

    .video-wrapper {
        ${tw`absolute inset-0`};
        height: 100%;
        padding: 0;
    }

    ${ImageWrapper}, .image-wrapper {
        ${tw`relative w-full`};
        height: 0;
        padding-bottom: 128.113879%;
        overflow: hidden;

        ${BGImage}, img {
            ${tw`absolute inset-0 w-full h-full`};
            object-fit: cover;
        }
    }

    ${Description} {
        margin-top: 1.5rem;

        ${media.phone`
            margin-top: 1rem;
        `}
    }
`

// Pagination
const Button = styled.div``
const Pagination = styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    margin-top: -1px;
    ${tw`border-t border-darkGrey`};

    ${Button} {
        padding: 0 0.5rem;
        cursor: pointer;
        font-size: 1rem;

        &.active {
            opacity: 0.3;
            cursor: initial;
        }
    }
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "news-page" } }) {
            nodes {
                title
                acf_json
            }
        }
    }
`

export default News
